select {
  border: 0px;
}

.admin-select {
  font-size: 1.25rem;
  margin-left: 1.75%;
}

.admin-buttons {
  display: flex;
  width: 100%;
  margin-bottom: 1.5%;
}

.admin-buttons input {
  width: 25%;
  margin-right: 2%;
}

.admin-buttons a {
  margin-left: auto;
  color: white;
}

.form-wizard {
  padding: 20px;
  background-color: white;
  margin-bottom: 10px;
}

@media all and (orientation: portrait) and (max-width: 415px) {
  .admin-select, .admin-select select {
    max-width: 80vw;
    font-size: 4vw;
  }

  .container .box {
    height: 100%;
    max-width: 100vw;
    padding: 0;
  }

  .form-wizard {
    position: relative;
    max-width: 100%;
  }

  .admin-buttons {
    flex-direction: column;
    width: 80vw;
    height: 25vh;
    justify-content: space-around;
  }

  .admin-buttons input, .admin-buttons .select, .admin-buttons .select select, .admin-buttons a {
    width: 100%;
    align-self: center;
    font-size: 4vw;
  }

  .admin-buttons input {
  }

  table {
    position: relative;
    left: -2.5%;
    max-width: 100%;
  }

  tr, td {
    max-height: 100%;
  }

  .admin-actions {
   justify-content: space-between;
   width: 120%;
   max-height: 100%;
  }

  .modal {
    z-index: 900;
    height: 100vh;
    width: 100vw;
  }

  .modal-card {
    max-height: 70vh;
    max-width: 80vw;
  }

  .modal-buttons .button{
    font-size: 3.5vw;
  }

  .modal-card-head p {
    font-size: 5vw;
  }

  .registration-form {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  form .field {
    width: 100%;
    margin-right: 10px;
  }
  form .field .label, form .field input, form .button {
    font-size: 3.5vw;
  }
}
