.tracks-header {
  height: 10vh;
  padding-left: 1.5%;
  padding-top: 1%;
}

.tracks-title {
  font-family: 'Fjalla One', sans-serif;
  background-color: black;
  letter-spacing: -3px;
  padding-right: 5px;
}

.tracks-subtitle {
  margin-top: 15px;
}

.form-wizard {
  padding: 20px;
  background-color: white;
  margin-bottom: 10px;
}

.lessons-container {
  position: relative;
  width: 75%;
  margin-bottom: 10px;
}

.add-lesson {
  color: hsl(171, 100%, 41%);
  cursor: pointer;
  margin-right: 20px;
}

.display-selected-lessons {
  margin-left: 5px;
  cursor: pointer;
}

.selected-lesson {
  margin-bottom: 5px;
}

.selected-lesson:first-of-type {
  margin-top: 5px;
}

.delete-icon {
  color: hsl(348, 100%, 61%);
  cursor: pointer;
  margin-right: 10px;
}

.lesson-reorder-item {
  height: 5%;
  width: 100%;
  margin-bottom: 15px;
}

.admin-actions {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
}

.placeholder-container {
  position: relative;
  left: 35%;
  top: 20%;
  width: 35%;
  height: auto;
}

.lesson-video {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.lesson-description {
  margin-top: 2.5%;
}


@media all and (orientation: portrait) and (max-width: 415px) {


}

@media all and (orientation: landscape) and (max-width: 897px) {

}
