.lessons-header {
  height: 10vh;
  padding-left: 1.5%;
  padding-top: 1%;
}

.lessons-title {
  font-family: 'Fjalla One', sans-serif;
  background-color: black;
  letter-spacing: -3px;
}

.lessons-subtitle {
  margin-top: 15px;
}

.lessons-container {
  height: 90vh;
}

.lessons-subcontainer, .lessons-subcontainer .lessons-container {
  height: 100%;
}

.lessons-subcontainer .lessons-container .pagination {
  position: relative;
  bottom: -10%;
}


@media all and (orientation: portrait) and (max-width: 320px) {

  .lessons {
    width: 100vw;
    height: auto;
  }

  .lessons-subcontainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .filter-container {
    width: 90vw;
    margin-bottom: 2.5%;
  }

  .filter-container .field .control .label, .filter-container .field .control .select, .filter-container .field .control .select select, .filter-container .field .control .select option {
    width: 100%;
  }

  .filter-container .field .control .label, .filter-container .field .control .select {
    font-size: 3vw;
  }

  .lessons-subcontainer .lessons-container {
    height: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .lessons-subcontainer .lessons-container .pagination {
    position: relative;
    bottom: 5%;
    left: 25%;
    align-self: flex-start;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    margin-left: 2.5%;
    font-size: 3vw;
  }

  .card {
    max-width: 90vw;
    max-height: 70vh;
  }

  .card-image .image {
    height: 100%;
    width: 100%;
  }

  .card-image .image img {
    height: 100%;
    width: 100%;
  }

}

@media all and (orientation: portrait) and (min-width: 326px) and (max-width: 415px) {

  .lessons {
    width: 100vw;
    height: auto;
  }

  .lessons-subcontainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .filter-container {
    width: 90vw;
    margin-bottom: 2.5%;
  }

  .filter-container .field .control .label, .filter-container .field .control .select, .filter-container .field .control .select select, .filter-container .field .control .select option {
    width: 100%;
  }

  .filter-container .field .control .label, .filter-container .field .control .select {
    font-size: 3vw;
  }

  .lessons-subcontainer .lessons-container {
    height: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  .lessons-subcontainer .lessons-container .pagination {
    position: relative;
    bottom: 5%;
    left: 25%;
    align-self: flex-start;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    margin-left: 2.5%;
    font-size: 3vw;
  }

  .card {
    max-width: 90vw;
    max-height: 70vh;
  }

  .card-image .image {
    height: 100%;
    width: 100%;
  }

  .card-image .image img {
    height: 100%;
    width: 100%;
  }
}


@media all and (orientation: portrait) and (min-width: 416px) and (max-width: 897px) {

  .lessons {
    width: 100vw;
  }

  .lessons-subcontainer {
    display: flex;
    flex-direction: column;
  }

  .filter-container {
    max-width: 90vw;
  }

  .filter-container .field .control .label, .filter-container .field .control .select, .filter-container .field .control .select select, .filter-container .field .control .select option {
    width: 100%;
  }

  .filter-container .field .control .label, .filter-container .field .control .select {
    font-size: 3vw;
  }

  .lessons-subcontainer .lessons-container {
    max-height: 100vh;
    width: 95%;
    position: relative;
    top: 180%;
    left: -10%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
  }

  .lessons-subcontainer .lessons-container .pagination {
    position: relative;
    bottom: 5%;
    left: 7%;
    align-self: flex-start;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    font-size: 3vw;
  }

  .card {
    max-width: 90vw;
    max-height: 70vh;
  }

  .card-image .image {
    height: 100%;
    width: 100%;
  }

  .card-image .image img {
    height: 100%;
    width: 100%;
  }

}
