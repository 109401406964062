
.lesson-header {
  margin-bottom: 10px;
}


.lessons-title {
  font-family: 'Fjalla One', sans-serif;
  background-color: black;
  letter-spacing: -3px;
  padding-right: 5px;
}

.lessons-subtitle {
  margin-top: 15px;
}


.lesson-metadata {
  margin-top: -15px;
  align-items: baseline;
}

.lesson-content {
  background-color: hsl(0, 0%, 50%);
  height: 70vh;
  width: 100vw;
  border-radius: 5px;
}

.lesson-comments {
  margin-top: 10px;
}

.lesson-item-title {
  font-size: 1rem;
  margin-bottom: 25px;
}

.lessons-container {
  position: relative;
  width: 75%;
  margin-bottom: 10px;
}

.lessons-subcontainer {
  display: flex;
  width: 100vw;
}


.lessons-container {
  margin-left: 20px;
}

.track-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.menu {
  background-color: white;
  width: 25%;
}

.menu-list {
  margin-left: 10px;
}

.menu-list li {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.lesson-content {
  width: 75%;
}

.lesson-title a {
  font-size: 1rem;
}

.lesson-title i {
  position: relative;
  color: hsl(141, 53%, 53%);
}

@media all and (orientation: portrait) and (max-width: 415px) {

  .container .box {
    max-width: 95vw;
    position: relative;
    left: 2.5%;
  }

  .container .box .lessons-title {
    font-size: 7vw;
  }

  .container .box .lessons-subtitle {
    font-size: 4vw;
  }

  .lesson-container {
    height: 100vw;
  }

  .box {
    height: 87vh;
  }

  .track-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .lesson-content {
    width: 100%;
    height: 35vh;
  }

  .menu {
    width: 100%;
  }

}

@media all and (orientation: landscape) and (max-width: 896px) {

  .container .box {
    max-width: 95vw;
    position: relative;
    left: 2.5%;
  }

  .container .box .lessons-title {
    font-size: 3vw;
  }

  .container .box .lessons-subtitle {
    font-size: 2vw;
  }


}
