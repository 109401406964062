
.dashboard {
  height: 100vh;
  width: 100%;
}

.profile-header {
  margin-top: 20px;
}

.hero {
  position: relative;
  top: -1%;
  margin-bottom: 1%;
  background-color: gray;
  background-image: url('../../assets/logo-transparent.png');
  background-repeat: no-repeat;
  background-position: center;
}

.hero-body {
  align-self: center;
  width: 100%;
}

.field {
  margin-top: -1%;
}

.profile-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-name {
  font-family: 'Fjalla One', sans-serif;
  font-size: 2rem;
  letter-spacing: -3px;
  background-color: black;
  padding-right: 5px;
  color: white;
}

.profile-edit {
  font-size: 1rem;
  color: white;
}

.profile-icons {
  text-align: center;
}

.profile-lessons {
  position: relative;
  left: 5%;
  width: 90%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.profile-lessons .box {
  width: 30%;
}

.hero-stats {
  display: flex;
  width: 40%;
  margin-top: 2.5%;
}

.hero-stat {
  display: flex;
  flex-direction: column;
  width: 20%;
}

.hero-stat-number {
  font-size: 1.75rem;
}

.hero-stat div {
  font-size: 1rem;
}

.profile-widget-title {
  font-family: 'Fjalla One', sans-serif;
  background-color: black;
  letter-spacing: -3px;
  padding-right: 5px;
}

.widget-list {
  margin-top: 10px;
  margin-bottom: 10px;
}

.new-user-modal p {
  font-family: 'Fjalla One', sans-serif;
  margin-bottom: 5px;
}

/* small to large phones - portrait mode */
@media all and (orientation: portrait) and (max-width: 942px) {

  .hero-body {
    display: none;
  }

  .modal-card-head p {
    max-width: 90%;
  }

  .profile-lessons {
    position: relative;
    left: 5%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .profile-lessons .box {
    height: 25%;
    width: 90%;
  }

  .box .profile-widget-title {
    font-size: 7vw;
  }

  .box ul li {
    font-size: 3vw;
  }

}

/* small to large phones - portrait mode */
@media all and (orientation: landscape) and (max-width: 896px) {

  #root {
    height: 100vh;
  }

  .modal-card-head p {
    max-width: 90%;
  }

  .hero-body {
    display: none;
  }

  .profile-lessons {
    position: relative;
    left: 5%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .profile-lessons .box {
    width: 80%;
  }

  .box .profile-widget-title {
    font-size: 2.5vw;
  }

  .box ul li {
    font-size: 1.5vw;
  }

}

/* tablets - portrait mode */
@media all and (orientation: portrait) and (min-width: 940px) and (max-width: 1024px) {


  .hero-stat {
    display: flex;
    flex-direction: column;
    width: 20%;
  }

  .profile-lessons {
    position: relative;
    left: 5%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .profile-lessons .box {
    width: 90%;
  }

}


/* tablets - landscape  mode */
@media all and (orientation: landscape) and (min-width: 1024px) and (max-width: 1111px){

  .hero-stat {
    display: flex;
    flex-direction: column;
    width: 20%;
  }

  .profile-lessons {
    position: relative;
    left: 5%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .profile-lessons .box {
    width: 90%;
  }

}

/* large tablets - landscape  mode */
@media all and (orientation: landscape) and (min-width: 1112px) and (max-width: 1439px){

  .profile-lessons {
    position: relative;
    left: 5%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .profile-lessons .box {
    width: 90%;
  }

}

/* large tablets - portrait mode */
@media all and (orientation: portrait) and (min-width: 1024px) {

  .profile-lessons {
    position: relative;
    left: 5%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .profile-lessons .box {
    width: 90%;
  }

}

/* mid to large monitors - landscape  mode */
@media all and (orientation: landscape) and (min-width: 2000px) and (max-width: 2167px){

  .hero-stats {
    display: flex;
    width: 100%;
    margin-top: 5%;
  }

  .profile-lessons {
    position: relative;
    left: 5%;
    width: 90%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

}


/* large monitors - landscape  mode */
@media all and (orientation: landscape) and (min-width: 2168px) and (max-width: 2562px){

  .hero-stats {
    display: flex;
    width: 100%;
    margin-top: 5%;
  }

  .profile-lessons {
    position: relative;
    left: 5%;
    width: 90%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

}
