
html {
   background-color: hsl(0, 0%, 96%);
 }

 html, body, #root {
 }

/* nav styles */

.mfc-navbar {
  font-family: 'Fjalla One', sans-serif;
  z-index: 200;
  position: sticky;
  top: 0%;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  padding: 5px 30px 5px 30px;
  background-color: rgba(54, 54, 54, 1);
  font-size: 1.75rem;
  margin-bottom: 10px;
  letter-spacing: -2px;
}

.auth-nav {
  background-color: rgba(54, 54, 54, 1);
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.nav-brand {
  cursor: pointer;
  color: white;
  align-self: center;
}

.mfc-navbar:nth-child(1) {
  align-self: flex-start;
}

.nav-brand:hover {
  color: white;
}

.menu-icon {
  z-index: 300;
  margin-left: 2.5%;
  width: 2.5%;
  align-self: center;
  margin-left: auto;
  height: auto;
  cursor: pointer;
}

.line {
  height: 5px;
  width: 100%;
  background-color: hsl(0, 0%, 96%);
  display: block;
  border-radius: 5%;
  transition: 0.3s ease;
}

#line1 {
  transform: translateY(-4px);
}

#line1::after {
  background-color: hsl(0, 0%, 96%);
}

#line3 {
  transform: translateY(4px);
}

.change-icon .line {
  background-color: hsl(0, 0%, 96%);
}

.change-icon #line1 {
  transform: translateY(4px) rotateZ(-45deg);
}

.change-icon #line3 {
  transform: translateY(-6px) rotateZ(45deg);
}

.change-icon #line2 {
  opacity: 0;
}

.nav-menu {
  position: absolute;
  z-index: 200;
  height: 200vh;
  width: 20vw;
  transform: translateX(100%);
  right: 0;
  margin-top: -2.5%;
  background-color: hsl(0, 0%, 4%);
}

.mobile-menu {
  position: absolute;
  height: 105vh;
  width: 100vw;
  transform: translateY(-100%);
  margin-top: -2.5%;
  margin-left: -1%;
  background-color: hsl(0, 0%, 4%);
}

.hide-menu {
  display: none;
}

.guest-links {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: flex-end;
  margin-left: auto;
}

.guest-links .nav-brand {
  margin-right: 10px;
}

.nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 10%;
  width: 100%;
}

.nav-links a {
  text-decoration: none;
  color: hsl(0, 0%, 96%);
}

.highlight-login {
  background-color: white;
  color: black;
}


.shake-bottom {
	-webkit-animation: shake-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: shake-bottom 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

@-webkit-keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}




/* login/registration form */

.auth-container {
  height: 75vh;
}

.auth-form-title {
  text-align: center;
}

.auth-form {
  width: 50%;
  position: relative;
  left: 25%;
  top: 15%;
}

.loading {
  position: absolute;
  top: 50;
}

#main {
  position: absolute;
  top: 0%;
}

/* hero image and banner text */

.landing {
  height: 100vh;
  width: 100vw;
}

.hero-banner {
  height: 100%;
  background-image: url("https://lh3.googleusercontent.com/u9ZOwjkrTBNEsFpZU0S4xMRJZei7-7i91kyn7D1uGeCCMAABxgd1WIuKd-twMsZOwdzbXsXLKC5fpTVfMV2PFKqI6KuwBjthd9Te0Kc-aA=s1600");
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-bg {
  height: 100%;
  background: rgb(5,5,55, .45);
}

.hero-img {
  display: none;
}

.banner-text {
  font-family: 'Fjalla One', sans-serif;
  position: relative;
  top: 30%;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  align-content: center;
  text-align: center;
  color: white;
}

.mfc-title {
  display: inline-block;
  font-family: 'Fjalla One', sans-serif;
  background-color: black;
  color: white;
  font-size: 4rem;
  padding-right: 5px;
  letter-spacing: -3px;
}

.subtitle {
  margin-top: -3%;
  color: white;
  letter-spacing: -1px;
  font-size: 2.5rem;
}

.banner-buttons {
  display: flex;
}

.banner-button {
  background-color: white;
  color: black;
  font-size: 2rem;
  border: 1px white solid;
  padding: 10px;
  letter-spacing: -1px;
  border-radius: 5px;
  cursor: pointer;
}

.banner-button {
  margin-right: 10px;
}

.banner-button:hover {
  background-color: black;
  color: white;
}

/* message */

.pricing {
  height: 100vh;
  width: 100vw;
  font-size: 2rem;
}

.pricing-header {
  position: absolute;
  font-family: 'Fjalla One', sans-serif;
}

.pricing-top {
  width: 100%;
}

.price {
  background-color: black;
  color: white;
  border-radius: 50%;
  width: 5%;
  padding: 10px;
  transform: rotate(-20deg);
  margin-left: 10px;
  margin-right: 10px;
}

.features {
  position: relative;
  top: 20%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.feature {
  height: 90%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: .5%;
  font-size: 2.5rem;
}

.feature-header {
  font-size: 3rem;
}

.feature-img {
  width: 50%;
  height: 400px;
  border-radius: 5px;
  margin-bottom: 10px;
  -webkit-box-shadow: 0px 0px 44px -11px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 44px -11px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 44px -11px rgba(0,0,0,0.75);
}

.feature-img img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
  border-radius: 5px;
}


/* dojo */

.dojo {
  height: 100vh;
  width: 100vw;
  display: block;
}

.dojo-img {
  height: 100%;
  width: 100vw;
  background: url('./assets/dojo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.dojo-about {
  max-height: 35%;
  max-width: 30%;
  color: white;
  float: right;
  position: relative;
  top: 35%;
  padding: 5px;
  background-color: black;
  font-size: 1vw;
}

/* about */

.about {
  height: 100vh;
  width: 100vw;
  background-color: #2A2A35;
  display: block;
  overflow-x: hidden;
  overflow-y: hidden;
}

.about-header {
  z-index: 100;
  position: absolute;
}

.slider {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.slide {
  width: 100vw;
  margin-right: 10px;
}

.slider img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
}

.slider-button {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  width: 5%;
  height: 10%;
  background: transparent;
  border: none;
}

.slider-button i {
  font-size: 2rem;
  color: #2A2A35;
  text-shadow:
   -1px -1px 0 #BED38E,
    1px -1px 0 #BED38E,
    -1px 1px 0 #BED38E,
     1px 1px 0 #BED38E;
}

.slider-button i:hover {
  cursor: pointer;
}

#left {
  left: 0%;
}

#right {
  right: 0%;
}

.slide-header {
  font-size: 2rem;
}

.slide-about {
  max-height: 30%;
  width: 100%;
  position: absolute;
  top: 72.4%;
  background-color: rgba(54, 54, 54, .5);
}

.slide-about span {
  font-family: 'Fjalla One', sans-serif;
  color: white;
  letter-spacing: -2px;
  font-size: 2.5rem;
}

.slide-about p {
  color: white;
  padding-top: 1%;
  padding-left: 10px;
  font-size: 1.75rem;

}

.slide ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -7%;
}

.slide li {
  margin: 0px 10px 10px 0px;
  background-color: #BED38E;
  color: #2A2A35;
}


.about-info {
  width: 50%;
  height: 100%;
  background-color: #4A4843;
  color: white;
}

.about-cost {
  position: relative;
  left: 5%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  padding: 0px 0px 0px 0px;
}

.hidden {
  visibility: hidden;
}

.fade-in {
  animation: fadeIn .5s ease-in;
}

.fade-out {
  animation: fadeOut .5s ease-in;
  animation-delay: 1s;
}


@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1
    }
}

@keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

.cost-info {
  display: flex;
  flex-direction: column;
  text-align: center;
}


/* contact */

.contact {
  height: 50vh;
  text-align: center;
  display: block;
  position: relative;
  background-color: rgb(54, 54, 54);
}

.contact-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}

.address-container {
  height: 100%;
  width: 100%;
  font-family: 'Fjalla One', sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
}

.address {
  font-size: 2.5rem;
}

.contact-social {
  width: 100%;
  display: flex;
  justify-content: center;
}

.contact-social a {
  margin-right: 1%;
}

.contact-social img {
  height: 65px;
  width: auto;
}

.contact-footer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

/* small to medium phones - portrait mode */
@media all and (orientation: portrait) and (max-width: 413px) {


  /* nav */
  .mfc-navbar {
    font-size: 1rem;
    letter-spacing: -1px;
  }

  .nav-brand {
    display: none;
  }

  #login {
    display: inline-block;
    position: absolute;
    right: 0%;
    top: 15%;
  }

  .nav-brand:hover {
    color: white;
  }

  .logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .guest-links {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-around;
    margin-left: auto;
  }

  .guest-links .nav-brand {
    margin-right: 10px;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 10%;
    width: 100%;
  }

  .nav-links a {
    text-decoration: none;
    color: hsl(0, 0%, 96%);
  }

  .line {
    height: 2px;
    width: 20px;
  }

  .change-icon #line1 {
    transform: translateY(0px) rotateZ(-45deg);
  }

  .change-icon #line3 {
    transform: translateY(-4px) rotateZ(45deg);
  }

  .change-icon #line2 {
    opacity: 0;
  }

  .mobile-menu {
    width: 110vw;
    margin-left: 2%;
  }

  .nav-links a {
    font-size: 2rem;
  }
  /* hero */

  .hero-banner {
    background-position: center;
  }

  /* hero */
  .mfc-title {
    font-size: 1.75rem;
  }

  .subtitle, .banner-button {
    font-size: 1.5rem;
  }

  /* dojo */

  .dojo {
    height: 100vh;
  }

  .dojo-about {
    top: 60%;
    height: 100%;
    max-width: 100%;
    font-size: 3.1vw;
    background-color: rgba(0, 0, 0, .5);
  }

  .dojo-img {
    background-size: cover;
    background-position: center;
  }

  .dojo-img .title {
    font-size: 2rem;
  }

  /* about */

  .about {
    height: 100vh;
  }

  .about-header {
    font-size: 2rem;
  }

  .slide-about {
    top: 70%;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
  }

  .slide-about span {
    font-size: 1.5rem;
  }

  .slide-about p {
    font-size: 3vw;
    width: 90%;
  }

  .slider-button {
    position: absolute;
    top: 50%;
  }

  #right {
    right: 5%;
  }



  /* pricing */

  .features {
    flex-direction: column;
  }

  .feature {
    width: 100%;
  }

  .feature:last-of-type {
    margin-top: 2.5%;
  }

  .feature-header {
    font-size: 9vw;
  }

  .feature-img {
    display: none;
  }

  .feature div {
    font-size: 5vw;
  }

  .feature ul {
    font-size: 5vw;
  }


  /* contact */

  .contact {
    height: 100vh;
  }

  .address-container {
    width: 100%;
    height: 50%;
    position: relative;
    top: 25%;
  }

  .address {
    font-size: 7vw;
  }

  .contact-social {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .contact-social img {
    height: 50px;
    width: auto;
  }

  .contact-footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: .8rem;
  }

}

/* small to medium phones - landscape mode */
@media all and (orientation: landscape) and (max-width: 569px) {


  /* nav */
  .mfc-navbar {
    font-size: 1rem;
    letter-spacing: -1px;
  }

  .nav-brand {
    display: none;
  }

  #login {
    display: inline-block;
    position: absolute;
    right: 0%;
    top: 15%;
  }

  .nav-brand:hover {
    color: white;
  }

  .logo {
    width: 20px;
    height: 30px;
    margin-right: 10px;
  }

  .guest-links {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-around;
    margin-left: auto;
  }

  .guest-links .nav-brand {
    margin-right: 10px;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 10%;
    width: 100%;
  }

  .nav-links a {
    text-decoration: none;
    color: hsl(0, 0%, 96%);
  }

  /* hero */

  .hero-banner {
    background-position: center;
  }

  /* hero */
  .mfc-title {
    font-size: 5vw;
  }

  .subtitle, .banner-button {
    font-size: 3vw;
  }

  /* dojo */

  .dojo {
    height: 100vh;
  }

  .dojo-about {
    top: 46.25%;
    height: 100%;
    max-width: 100%;
    font-size: 2vw;
    background-color: rgba(0, 0, 0, .5);
  }

  .dojo-img {
    background-size: cover;
    background-position: center;
  }

  .dojo-img .title {
    font-size: 2rem;
  }

  /* about */

  .about {
    height: 100vh;
  }

  .about-header {
    font-size: 2rem;
  }

  .slide-about {
    top: 59%;
    max-height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
  }

  .slide-about span {
    font-size: 3vw;
  }

  .slide-about p {
    font-size: 2vw;
    width: 100%;
  }

  .slider-button {
    position: absolute;
    top: 50%;
  }

  #right {
    right: 5%;
  }



  /* pricing */

  .pricing-header {
  }

  .features {
    flex-direction: row;
    top: 15%;
  }

  .feature {
    width: 100%;
  }

  .feature-header {
    font-size: 4vw;
  }

  .feature-img {
    display: none;
  }


  .feature ul li {
    font-size: 2vw;
  }


  /* contact */

  .contact {
    height: 100vh;
  }

  .address-container {
    width: 100%;
    top: 20%;
  }

  .address {
    font-size: 3.5vw;
  }

  .contact-social {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
  }

  .contact-social img {
    height: 50px;
    width: auto;
  }

  .contact-footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: .8rem;
  }

}

/* larger phones and small tablets - portrait mode */
@media  all and (orientation: portrait) and (min-width: 414px) and (max-width: 763px) {

  /* nav */

  .nav-brand {
    display: none;
  }

  .logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  #login {
    display: inline-block;
    position: absolute;
    right: 0%;
    top: 15%;
    font-size: 1.5rem;
  }

  .line {
    height: 2px;
    width: 20px;
  }

  .change-icon #line1 {
    transform: translateY(0px) rotateZ(-45deg);
  }

  .change-icon #line3 {
    transform: translateY(-4px) rotateZ(45deg);
  }

  .change-icon #line2 {
    opacity: 0;
  }

  .mobile-menu {
    width: 110vw;
    margin-left: 2%;
  }

  /* hero */
  .hero-banner {
    background-position: center;
  }

  .mfc-title {
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 2rem;
  }

  .banner-button {
    font-size: 2rem;
  }

  /* dojo */

  .dojo {
    height: 100vh;
  }

  .dojo-about {
    top: 60%;
    height: 40%;
    max-width: 100vw;
    font-size: 3vw;
    background-color: rgba(0, 0, 0, .5);
  }

  .dojo-img {
    background-position: center;
  }

  /* about */

  .about {
    height: 100vh;
  }

  .about-header {
    position: absolute;
    top: 40%;
    left: 0;
  }

  .slide img {
    height: 60%;
  }

  .slide-about {
    bottom: 0%;
    background-color: rgba(0, 0, 0, .5)
  }

  .slide-about span {
    font-size: 2rem;
  }

  .slide-about p {
    font-size: 2.5vw;
    width: 85%;
  }

  .slider-button {
    top: 50%;
    z-index: 300;
  }

  #right {
    right: 5%;
  }


  /* pricing */

  .features {
    flex-direction: column;
  }

  .feature {
    width: 100%;
  }

  .feature-header {
    font-size: 6vw;
  }

  .feature:last-of-type {
    margin-top: 2.5%;
  }

  .feature-img {
    display: none;
  }

  .feature div, .feature ul {
    font-size: 5vw;
  }

  .feature ul {
    padding: 5%;
  }


  /* contact */

  .contact {
    height: 100vh;
  }

  .address-container {
    width: 100%;
    height: 50%;
    position: relative;
    top: 25%;
  }

  .address {
    font-size: 5vw;
  }

  .contact-social {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .contact-social img {
    height: 50px;
    width: auto;
  }

  .contact-footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: .8rem;
  }

}

/* mid-size phones - landscape mode */
@media all and (orientation: landscape) and (max-width: 737px) {


  /* nav */
  .mfc-navbar {
    font-size: 1rem;
    letter-spacing: -1px;
  }

  .nav-brand {
    display: none;
  }

  #login {
    display: inline-block;
    position: absolute;
    right: 0%;
    top: 15%;
  }

  .nav-brand:hover {
    color: white;
  }

  .guest-links {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-around;
    margin-left: auto;
  }

  .guest-links .nav-brand {
    margin-right: 10px;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 10%;
    width: 100%;
  }

  .nav-links a {
    text-decoration: none;
    color: hsl(0, 0%, 96%);
  }

  /* hero */

  .hero-banner {
    background-position: center;
  }

  /* hero */
  .mfc-title {
    font-size: 5vw;
  }

  .subtitle, .banner-button {
    font-size: 3vw;
  }

  /* dojo */

  .dojo {
    height: 100vh;
  }

  .dojo-about {
    top: 50.5%;
    height: 100%;
    max-width: 100%;
    font-size: 1.75vw;
    background-color: rgba(0, 0, 0, .5);
  }

  .dojo-img {
    background-size: cover;
    background-position: center;
  }

  .dojo-img .title {
    font-size: 2rem;
  }

  /* about */

  .about {
    height: 100vh;
  }

  .about-header {
    font-size: 2rem;
    position: absolute;
    top: 40%;
    left: 0;
  }

  .slide-about {
    top: 0%;
    max-height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
  }

  .slide-about span {
    font-size: 3vw;
  }

  .slide-about p {
    font-size: 1.75vw;
    width: 100%;
  }

  .slider-button {
    position: absolute;
    top: 50%;
  }

  #right {
    right: 5%;
  }



  /* pricing */

  .features {
    flex-direction: row;
    top: 15%;
  }

  .feature {
    width: 100%;
  }

  .feature-header {
    font-size: 4vw;
  }

  .feature-img {
    display: none;
  }

  .feature ul li {
    font-size: 2vw;
  }


  /* contact */

  .contact {
    height: 100vh;
  }

  .address-container {
    width: 100%;
    top: 20%;
  }

  .address {
    font-size: 3vw;
  }

  .contact-social {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
  }

  .contact-social img {
    height: 50px;
    width: auto;
  }

  .contact-footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: .8rem;
  }

}

/* larger phones and tablets - landscape mode */
@media all and (orientation: landscape) and (max-width: 960px) {


  /* nav */
  .mfc-navbar {
    font-size: 1rem;
    letter-spacing: -1px;
  }

  .nav-brand {
    display: none;
  }

  #login {
    display: inline-block;
    position: absolute;
    right: 0%;
    top: 15%;
    background-color: rgba(0, 0, 0, .5);
  }

  .nav-brand:hover {
    color: white;
  }

  .guest-links {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-around;
    margin-left: auto;
  }

  .guest-links .nav-brand {
    margin-right: 10px;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 10%;
    width: 100%;
  }

  .nav-links a {
    text-decoration: none;
    color: hsl(0, 0%, 96%);
  }

  /* hero */

  .hero-banner {
    background-position: center;
  }

  /* hero */
  .mfc-title {
    font-size: 5vw;
  }

  .subtitle, .banner-button {
    font-size: 3vw;
  }

  /* dojo */

  .dojo {
    height: 100vh;
  }

  .dojo-about {
    top: 50.5%;
    height: 100%;
    max-width: 100%;
    font-size: 1.75vw;
    background-color: rgba(0, 0, 0, .5);
  }

  .dojo-img {
    background-size: cover;
    background-position: center;
  }

  .dojo-img .title {
    font-size: 2rem;
  }

  /* about */

  .about {
    height: 100vh;
  }

  .about-header {
    font-size: 2rem;
    position: absolute;
    top: 40%;
    left: 0;
  }

  .slide-about {
    top: 68.5%;
    max-height: 60%;
    background-color: rgba(0, 0, 0, .5);
  }

  .slide-about span {
    font-size: 3vw;
  }

  .slide-about p {
    font-size: 1.75vw;
  }

  .slider-button {
    position: absolute;
    top: 50%;
  }

  #right {
    right: 5%;
  }



  /* pricing */

  .features {
    flex-direction: row;
  }

  .feature {
    width: 100%;
  }

  .feature-header {
    font-size: 4vw;
  }

  .feature-img {
    display: none;
  }

  .feature div, .feature ul {
    font-size: 2vw;
  }


  /* contact */

  .contact {
    height: 100vh;
  }

  .address-container {
    width: 100%;
    top: 20%;
  }

  .address {
    font-size: 3vw;
  }

  .contact-social {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
  }

  .contact-social img {
    height: 50px;
    width: auto;
  }

  .contact-footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: .8rem;
  }

}

/* tablets - portrait mode */
@media all and (orientation: portrait) and (min-width: 764px) and (max-width: 1160px) {

  /* nav */

  .nav-brand {
    font-size: 1.25rem;
  }


  /* hero */
  .hero-banner {
    background-position: center;
  }

  /* dojo */

  .dojo-about {
    top: 30%;
    max-height: 60%;
    max-width: 50%;
    font-size: 2vw;
    background-color: rgba(0, 0, 0, .5);
  }

  .dojo-img {
    background-position: center;
    background-size: cover;
  }


  /* about */

  .about-header {
    position: absolute;
    top: 44.45%;
    left: 0;
  }

  .slide img {
    object-position: center;
  }

  .slide-about {
    background-color: rgba(0, 0, 0, .5);
    bottom: 0%;
  }

  .slide-about p {
    font-size: 2.5vw;
  }

  /* pricing */

  .feature-header {
    font-size: 4vw;
  }

  .feature-img {
    height: 200px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .feature-img img {
    height: 200px;
    width: 100%;
    object-position: bottom;
  }



}

/* tablets - landscape mode */
@media all and (orientation: landscape) and (min-width: 764px) and (max-width: 1160px) {

  /* nav */

  .nav-brand {
    font-size: 1.25rem;
  }


  /* hero */
  .hero-banner {
    background-position: center;
  }

  /* dojo */

  .dojo-about {
    top: 30%;
    max-height: 60%;
    max-width: 50%;
    font-size: 2vw;
    background-color: rgba(0, 0, 0, .5);
    font-size: 1.65vw;
  }

  .dojo-img {
    background-position: center;
    background-size: cover;
  }


  /* about */

  .about-header {
    position: absolute;
    top: 43.45%;
    left: 0;
  }

  .slide-about {
    background-color: rgba(0, 0, 0, .5);
    top: 55%;
    height: 45%;
  }

  .slide-about p {
    font-size: 1.9vw;
  }

  /* about */

  .about {
    height: 100vh;
  }

  .about-header {
    font-size: 2rem;
    position: absolute;
    top: 40%;
    left: 0;
  }

  .slide-about {
    top: 55%;
    max-height: 55%;
    background-color: rgba(0, 0, 0, .5);
  }

  .slide-about span {
    font-size: 3vw;
  }

  .slide-about p {
    font-size: 1.75vw;
  }

  .slider-button {
    position: absolute;
    top: 50%;
  }

  #right {
    right: 5%;
  }

  /* pricing */

  .pricing {
    height: 100vh;
  }

  .features {
    flex-direction: row;
    top: 20%;
  }

  .feature {
    width: 100%;
  }

  .feature-header {
    font-size: 4vw;
  }

  .feature-img {
    display: none;
  }

  .feature div, .feature ul {
    font-size: 3vw;
  }


  /* contact */

  .contact {
    height: 100vh;
  }

  .address-container {
    width: 100%;
    top: 20%;
  }

  .address {
    font-size: 3vw;
  }

  .contact-social {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-evenly;
  }

  .contact-social img {
    height: 50px;
    width: auto;
  }

  .contact-footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: .8rem;
  }
}


/* laptops */
@media all and (orientation: landscape) and (min-width: 1161px) and (max-width: 1675px) {

  /* hero */
  .hero-banner {
    background-position: center;
  }

  /* dojo */

  .dojo-about {
    top: 30%;
    max-height: 60%;
    max-width: 30%;
    font-size: 1.25vw;
  }

  .dojo-img {
    background-position: right;
  }

  /* about */

  .slide-about {
    top: 70%;
    height: 50%;
  }

  .slide-about p {
    font-size: 1.5vw;
    max-width: 90%;
  }

  /* pricing */

  .features {
  }

  .feature-img {
    height: 200px;
    width: 200px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .feature-img img {
    height: 200px;
    width: 100%;
    object-position: bottom;
  }

  .feature h1 {
    font-size: 3vw;
  }

  .feature ul, .feature div {
    font-size: 2vw;
  }

  .feature ul {
    width: 70%;
  }

  .feature ul li {
    margin-bottom: 5%;
  }

  /* contact */

  .contact {
    height: 60vh;
  }

  .address .mfc-title {
    font-size: 3vw;
  }

  .address p {
    font-size: 2.5vw;
  }

  .contact-social {
    width: 100%;
    justify-content: space-evenly;
  }

}

/* monitors - landscape mode */
@media all and (orientation: landscape) and (min-width: 1676px) {

  /* about */

  .about-header {

  }

  .slider, .slide {
    top: -11%;
  }

  .slide {
    background-position: bottom;
  }

  .slide-about {
    top: 70%;
    height: 30%;
    background-color: rgba(54, 54, 54, .5);
  }

  .slide-about p {
    margin-top: -1.5%;
    font-size: 1.75rem;
  }

  /* pricing */

  .features {
  }

  .feature-img {
    height: 200px;
    width: 200px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .feature-img img {
    height: 200px;
    width: 100%;
    object-position: bottom;
  }

  .feature h1 {
    font-size: 3vw;
  }

  .feature ul, .feature div {
  }

  .feature ul li {
    margin-bottom: 5%;
  }
}
